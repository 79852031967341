import React from "react";
import H from "./H";

export default function H3({
    children
}) {
    return (
        <H
            tagName="h2"
            level={3}
            headingClassName="mt-6 text-2xl leading-8 font-extrabold tracking-tight text-theme_blue border-b-2 border-theme_blue border-x-none border-t-none"
        >
            {children}
        </H>
    )
}