import React from "react";


export default function TableLink({
    href,
    title,
    children
}) {
    return (
        <a
            href={href}
            title={title}
        >
            {children}
        </a>
    )
}