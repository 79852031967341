import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import H2 from "./Content/H2";
import AdressFormular from "./Form/AdressFormular";
import TextField from "./Form/TextField";
import RegistrationHeader from "./RegistrationHeader";

export default function HomeContent() {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);

    const getMail = () => {
        if (baseInfo && "email" in baseInfo) {
            return baseInfo.email;
        } else {
            return ""
        }
    }
    const email = getMail();

    const [formState, setFormState] = useState({
        name: baseInfo?.name || "",
        strasse: baseInfo?.strasse || "",
        nummer: baseInfo?.nummer || "",
        zivilstand: baseInfo?.zivilstand || "",
        telefon: baseInfo?.telefon || "",
        geburtsdatum: baseInfo?.geburtsdatum || "",
        vorname: baseInfo?.vorname || "",
        plz: baseInfo?.plz || "",
        ort: baseInfo?.ort || "",
        email: baseInfo?.email || email,
    });

    const onSubmit = () => {
        console.log("submit", formState);
        setBaseInfo(formState);
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFormState({
            ...formState,
            [name]: value,
        })
    }

    return (
        <div className="py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
            <div className="max-w-max lg:max-w-7xl mx-auto px-2">
                <div className="relative z-10 mb-8 md:mb-2 md:px-6">

                    <RegistrationHeader
                        active={0}
                    />

                    <H2>
                        Registriere dich jetzt bei getyourhouse und sichere dir deine Traumimmobilie
                    </H2>
                </div>
                <div className="relative">

                    <div className="relative md:bg-white md:p-6">

                        <AdressFormular
                            handleInputChange={handleInputChange}
                            formState={formState}
                        />


                        <div className="mt-24 flex justify-center">
                            <a
                                href="/registrieren-2"
                                onClick={onSubmit}
                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base cursor-pointer text-white bg-theme_green hover:bg-theme_blue transition-colors font-bold"
                            >
                                zum nächsten Schritt
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}